
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Visor } from "@/models/visors";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { mapApiDataMerger, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import Search from "@/components/shared/Search.vue"

  export default defineComponent({
    components: {
      Search
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = ref<number>(0)
      // const visors = computed(() => root.$store.state.visors.data)
      // const postings = computed(() => root.$store.state.postings.data)

      const getItems = async(ctx: BvTableCtxObject) => {
        const visorQuery = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          sort: '-createdAt',
          include: 'careStays',
        }
        // const postingIds = extractRelationshipIds({ data: visors.value }, 'posting')
        // const postQueryParams = {
        //   'page[number]': 1,
        //   'page[size]': postingIds.length,
        //   'filter[id]': postingIds.join(','),
        //   include: 'household'
        // }
        // await root.$store.dispatch('postings/load', postQueryParams)
        // const vis = visors.value
        // const posts = postings.value
        // mapApiDataMerger(vis, posts, 'posting')
        // return vis
        const response = await root.$store.dispatch('visors/load', visorQuery)
        totalItems.value = response.meta.totalItems
        return response.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.designation",
          title: "Stelle",
          // sortable: true,
          options: { hrefFunction: (data: any) => resourceUrl(data.item) }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.arrivalDate",
          title: "gewünschte Anreise",
          // sortable: true,
          options: { type: 'date' }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.careStays",
          title: "Anzahl Vorschläge",
          sortable: true,
          options: { contentFunction: (data: any) => (data.item.attributes.careStays?.length || 0) }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.lastActivated",
          title: "letzte Aktivierung",
          options: { type: 'datetime' }
            // contentFunction: (data: any) => !!data.item.attributes.lastActivated
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.disabled",
          title: "Offline",
        },
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   key: "attributes.subject",
        //   title: "Betreff",
        //   sortable: true,
        //   options: {
        //     hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`
        //   }
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.archived",
        //   title: "Archiviert",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "messageCount",
        //   title: "Nachrichten",
        //   sortable: true,
        //   options: {
        //     contentFunction: (data: any) => data.item.attributes.messages.length
        //   }
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "lastMessage",
        //   title: "Letzte Nachricht",
        //   sortable: true,
        //   options: { type: 'date' }
        // },
      ])

      const searchColumns = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.designation",
          title: "Stelle",
          options: {hrefFunction: (data: any) => resourceUrl(data.item)}
        }
      ]

      const searchFunction = (query: {[key: string]: string}) => {
        return root.$store.dispatch('visors/search', query)
      }

      const searchOptions = {
        searchFunction: searchFunction,
        columns: searchColumns,
        placeholder: 'Nach Vorname, Nachname oder Fall-Id suchen'
      }

      return {
        getItems,
        totalItems,
        columns,
        searchFunction,
        searchColumns,
        searchOptions,
      }
    }
  })
